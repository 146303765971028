<template>
  <div>
    <v-alert text prominent type="error" icon="mdi-cloud-alert" v-if="errorMessage!=''">
      {{errorMessage}}
    </v-alert>
    <div class="d-flex justify-content-center align-items-center" style="height: 96vh;">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 mx-auto">
            <div class="bg-white rounded-pill-custom border p-5" style="box-shadow: 1px 1px 3px 0px #0000005e;padding: 5%;">
              <div class="text-center">
                <a href="https://claseleelee.com/">
                  <img class="pb-3" style="width: 250px;" src="../assets/homepage/Logo-LeeLee.png" alt="">
                </a>
              </div>
              <div class="row mb-3">
                <div class="col-md-4">
                  <v-btn icon color="blue" width="100" height="100" class="ma-4" to="/login-student">
                    <img src="../assets/homepage/qr.svg" style="color: aqua; width: 60%; margin-left: 5%;">
                  </v-btn>
                </div>
                <div class="col-md-8">
                  <v-form ref="form" @submit.prevent="onSubmit" lazy-validation>
                    <div class="form-group">
                      <label class="text-dark" for="email">Correo electrónico:</label>
                      <div class="border border-info text-center p-2 rounded-pill-custom mr-3">
                          <i class="mdi mdi-account" aria-hidden="true"></i>
                          <!--<input type="email" style="width: calc(100% - 35px);" class="border-0 form-control d-inline-block" id="email" placeholder="Ingrese su correo electrónico">-->
                          <v-text-field name="email" label="Ingrese su correo electrónico" type="text" class="border-0 form-control d-inline-block" style="width: calc(100% - 35px);" v-model="account.email" :rules="nameRules" outlined flat solo></v-text-field>
                      </div>                      
                    </div>
                    <div class="form-group">
                      <label  class="text-dark" for="password">Contraseña:</label>
                      <div class="border border-info text-center p-2 rounded-pill-custom mr-3">
                          <i class="mdi mdi-lock" aria-hidden="true"></i>
                          <!--<input type="password" style="width: calc(100% - 35px);" class="border-0 form-control d-inline-block" id="password" placeholder="Ingrese su contraseña">-->
                          <v-text-field name="password" label="Ingrese su contraseña" :type="showPassword ? 'text' : 'password'" class="border-0 form-control d-inline-block" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword" style="width: calc(100% - 35px); border: none !important;" v-model="account.password" :rules="nameRules" outlined flat solo></v-text-field>
                      </div>                      
                    </div>                    
                    
                    <div class="text-right">
                      <a @click="$router.push({name: 'forgotpassword'})" class="c-pointer px-4">Olvidó la contraseña?</a>
                      <v-btn color="#17a2b8" dark type="submit" class="btn btn-info rounded-pill-custom">Iniciar sesión</v-btn>
                    </div>
                  </v-form>
                </div>
              </div>
              <hr>
              <div class="text-center">
                <v-btn elevation="4" class="mt-2" style="width: 80%;" outlined color="blue-grey darken-2" block @click="LoginWithGoogle">
                  <img width="30px" src="../assets/admin/buscar.svg" class="mr-2">
                  <label>Iniciar sesión con Google</label>
                </v-btn>
                <a href="https://clever.com/oauth/authorize?response_type=code&redirect_uri=https://apps.claseleelee.com/oauth2&client_id=70d77ad860ae2e735c9d" class="btn border bg-white w-100 text-primary d-block mt-3 mb-5 elevation-4" style="text-decoration: none;font-weight: bold;">Iniciar sesión con Clever</a>                  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ul class="list-inline font-size-sm text-center">
      <li class="list-inline-item">© Escuela de Lectura LLC 2023</li>
      <li class="list-inline-item"><a class="text-reset text-decoration-none" href="">Legal</a></li>
      <li class="list-inline-item"><a class="text-reset text-decoration-none" href="privacy-policy">Privacy</a></li>
      <li class="list-inline-item"><a class="text-reset text-decoration-none" href="licensetermsuse">Terms &amp; Conditions</a></li>
    </ul>
  </div>
</template>

<script>
import { auth, functions } from '../plugins/firebase'

export default {
  name: 'Login',
  data: () => ({
    account: {
      email: '',
      password: ''
    },
    nameRules: [
      v => (v && v.length >= 1) || 'You need to complete the field',
      v => (v && v.length <= 50) || 'Name must be less than 50 characters'
    ],
    errorMessage: '',
    typeAlert: '',
    showPassword: false,
  }),
  created () {
    console.log(this.$store.state.user)
  },
  methods: {
    onSubmit () {
    //window.history.forward(1);
      console.log(this.account)
      if (!this.$refs.form.validate()) return;
      this.$store.dispatch('login', this.account).then(() => {        
        console.log(this.$store.state.user.role)
        location.reload();
        // if (this.$store.state.user.role == 'schoolTeacher') {
        //   location.href = "schoolTeacher";
        // }else{
        //   location.reload();
        // }
      }).catch(error => {
        console.log(error);
        if (Object.prototype.hasOwnProperty.call(error, 'message')) {
          this.MessageAlert(error.message, 'error');
        }else
          this.MessageAlert(error, 'error');
      });
    },
    LoginWithGoogle () {
      let localStore =this.$store;
      let provider = new auth.GoogleAuthProvider();
      auth().signInWithPopup(provider).then(
        function (result) {
          console.log(result)
              // var token = result.credential.idToken;
              var user = result.user;

              //this is what you need
              var isNewUser = result.additionalUserInfo.isNewUser;
              if (isNewUser) {
                console.log('usuario eliminado')
                  //delete the created user
                  result.user.delete();
              } else {
                  // your sign in flow
                  console.log('user ' + user.email + ' does exist!');
                  localStore.dispatch('loginGoogle').then(() => {                    
                    if (localStore.state.user.role == 'schoolTeacher') {                      
                      location.href = "schoolTeacher";
                    }else{
                      location.reload();
                    }
                  }).catch(error => {
                    console.log(error);
                    this.errorMessage = error;
                  });
              }
        }).catch(function (error) {
          console.log(error)
        });
    },
    MessageAlert ( message, type) {
      this.typeAlert = type;
      this.errorMessage = message;
      setTimeout(() => this.errorMessage = '', 4000);
    },
    async verifyVersion(){
        //console.log(process.env.VUE_APP_VERSION)
        let localVersion =process.env.VUE_APP_VERSION;
        const getVersion = functions.httpsCallable('getVersion')
        let response =await getVersion()
        if (response.data != localVersion) {
          //este proceso es solo para recargar una vez al dia
          var now = new Date();
          let memoria = localStorage.getItem('update');
          now.setHours(0,0,0,0);
          console.log(memoria)
          console.log(now)
          if (memoria != null) {
            let memoriaDate=new Date(memoria);
            if (now.getTime() > memoriaDate.getTime()) {
              console.log("necesita actualizar")
              sessionStorage.clear()
              localStorage.clear()
              caches.keys().then(keys => {
                keys.forEach(key => caches.delete(key))
              })
              indexedDB.databases().then(dbs => {
                dbs.forEach(db => indexedDB.deleteDatabase(db.name))
              })
              console.log("necesita actualizar")
              indexedDB.databases().then(dbs => { dbs.forEach(db => { if(db.name!="firebaseLocalStorageDb"){indexedDB.deleteDatabase(db.name)} }) })
              var datenow = new Date();
              datenow.setHours(0,0,0,0);
              localStorage.setItem('update',datenow )
              location.reload();
            }else{
               console.log("recargo el mismo dia")
              return;
            }
          }else{
              var datenow = new Date();
              datenow.setHours(0,0,0,0);
              caches.keys().then(keys => {
                keys.forEach(key => caches.delete(key))
              })
              indexedDB.databases().then(dbs => {
                dbs.forEach(db => indexedDB.deleteDatabase(db.name))
              })
              console.log("necesita actualizar")
              indexedDB.databases().then(dbs => { dbs.forEach(db => { if(db.name!="firebaseLocalStorageDb"){indexedDB.deleteDatabase(db.name)} }) })
              localStorage.setItem('update',datenow )
              
              location.reload();
          } 
          
          caches.keys().then(keys => {
            keys.forEach(key => caches.delete(key))
          })
          indexedDB.databases().then(dbs => {
            dbs.forEach(db => indexedDB.deleteDatabase(db.name))
          })
     
          indexedDB.databases().then(dbs => { dbs.forEach(db => { if(db.name!="firebaseLocalStorageDb"){indexedDB.deleteDatabase(db.name)} }) })
        }
    }
  },
  mounted () {
    this.verifyVersion();
    // ---------------aqui empieza el antiguo------
    // var now = new Date();
    // let memoria = localStorage.getItem('update');
    // now.setHours(0,0,0,0);
    // console.log(memoria)
    // console.log(now)
    // if (memoria != null) {
    //   let memoriaDate=new Date(memoria);
    //   if (now.getTime() > memoriaDate.getTime()) {
    //     console.log("necesita actualizar")
    //   }else{
    //      console.log("actualizado")
    //     return;
    //   }
    // }
    // sessionStorage.clear()
    // localStorage.clear()
    // caches.keys().then(keys => {
    //   keys.forEach(key => caches.delete(key))
    // })
    // indexedDB.databases().then(dbs => {
    //   dbs.forEach(db => indexedDB.deleteDatabase(db.name))
    // })
    // var datenow = new Date();
    // datenow.setHours(0,0,0,0);
    // localStorage.setItem('update',datenow )
    // location.reload();
    // console.log("paso")
    // ---------------hasta aqui el antiguo------
    // const getVersion = functions.httpsCallable('getVersion') // loginClever    --- loginCleverDemo
    // getVersion().then((result)=> {
    //   if (result.data != process.env.VUE_APP_VERSION) {
    //     console.log('entro')
    // window.history.forward(1);
    //     sessionStorage.clear()
    //     localStorage.clear()
    //     caches.keys().then(keys => {
    //       keys.forEach(key => caches.delete(key))
    //     })

    //     indexedDB.databases().then(dbs => {
    //       dbs.forEach(db => indexedDB.deleteDatabase(db.name))
    //     })
    //     var datenow = new Date();
    //     datenow.setHours(0,0,0,0);
    //     localStorage.setItem('update',datenow )
    //     location.reload();
    //   }
    // }) 
  }
}
</script>
<style>
  .form-group {
    margin-bottom: 1rem;
  }

  .v-text-field--outlined.v-input--is-focused fieldset, .v-text-field--outlined.v-input--has-state fieldset {
    border: none !important;
  }

  .v-text-field--outlined fieldset {
    border: none !important;
  }

  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding-top: 0px;
    margin-bottom: -18px;
  }

  .rounded-pill-custom {
    border-radius: 1.25em;
  }

  .text-center {
    text-align: center!important;
  }

  /*.p-2 {
      padding: 0.5rem!important;
  }*/
  .border-info {
      border-color: #17a2b8!important;
  }
  .border {
      border: 1px solid #dee2e6!important;
  }

  .v-application .elevation-4 {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
  }
</style>